import type { ImportLease } from '../components/Workflow/types'
import type { Ref } from 'vue'
import { isImportDocumentToReview } from '@workflow'

export const usePublishImportDocument = (
  companyId: string,
  selectedDocuments: Ref<ImportLease[]>,
  emitEvent: () => void,
) => {
  const { publishDocument } = useApiMutateLeaseDocuments()
  const importDocumentIds = computed(() =>
    selectedDocuments.value
      .filter((doc) => isImportDocumentToReview(doc) && doc.lease)
      .map((doc) => doc.lease!.id!),
  )

  return useConfirmHandler(
    () =>
      publishDocument.mutateAsync({
        leaseIds: importDocumentIds.value,
      }),
    {
      modal: {
        title: 'Add lease to Portfolio',
        body: 'Are you sure you want to add it to Portfolio?',
        submitButton: 'Add',
        cancelButton: 'Cancel',
        confirmClass:
          'bg-primary hover:bg-primary-700 text-black hover:ring ring-primary ring-opacity-30 focus:bg-primary',
        documents: selectedDocuments,
      },
      errorMessage: 'Error when publishing documents',
      submitHandlerOptions: {
        beforeSubmit: () => {
          if (!companyId || importDocumentIds.value.length === 0) return false
        },
        onSuccess: () => {
          emitEvent()
        },
      },
    },
  )
}
