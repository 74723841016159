<script lang="ts" setup>
import {
  useDeleteImportDocument,
  usePublishImportDocument,
  isImportDocumentToReview,
  type DocumentForModal,
} from '@workflow'
import type { ImportLease } from './types'

interface Props {
  selectedRows: ImportLease[]
  showPublishButton?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showPublishButton: false,
})

const companyId = getCurrentDivisionId()

const selectedDocuments = computed(() => {
  return props.selectedRows
})

// TODO: fix type "doc"
const publishableDocuments = computed(() =>
  props.selectedRows.filter((doc) => isImportDocumentToReview(doc)),
)

const emit = defineEmits<{
  (e: 'clear-selected'): void
}>()

// DELETE
const deleteDocuments = computed<DocumentForModal[]>(() => {
  return selectedDocuments.value.map((doc) => ({
    id: doc.id,
    filename: doc.filename,
    name: doc.lease?.name,
  }))
})
const openDeleteConfirmation = useDeleteImportDocument(
  companyId,
  deleteDocuments,
  'workflow',
  () => emit('clear-selected'),
)

function showModalArchiveMultiple(): void {
  openDeleteConfirmation()
}

// PUBLISH
const openPublishModal = usePublishImportDocument(
  companyId,
  publishableDocuments,
  () => emit('clear-selected'),
)

function showModalPublish(data: any): void {
  if (data.length === 0) return
  openPublishModal()
}
</script>

<template>
  <div class="document-review flex justify-between">
    <div class="flex w-full justify-end">
      <div class="flex h-10 gap-2">
        <Button
          v-if="selectedRows?.length"
          color="opacityDanger"
          class="flex items-center"
          @click="showModalArchiveMultiple"
        >
          <Icon name="trash" class="mr-1.5 fill-transparent text-lg" filled />
          <span class="text-md whitespace-nowrap">
            Delete ({{ selectedRows?.length }})
          </span>
        </Button>

        <Tooltip
          v-if="showPublishButton"
          :content="
            publishableDocuments?.length === 0
              ? 'Select the leases before'
              : undefined
          "
          placement="left"
          class="bg-gray-950 text-sm"
        >
          <Button
            color="primary"
            class="whitespace-nowrap"
            :disabled="publishableDocuments?.length === 0"
            @click="showModalPublish"
          >
            Add to Portfolio
            <span v-if="publishableDocuments?.length" class="ml-1">
              ({{ publishableDocuments?.length }})
            </span>
          </Button>
        </Tooltip>
      </div>
    </div>
  </div>
</template>
