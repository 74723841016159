import { DocumentType } from '@register'
import type { IImportDocument, IImportDocumentToReview } from '@workflow'

export const isImportDocumentToReview = (
  data: IImportDocument & IImportDocumentToReview,
): data is IImportDocument & IImportDocumentToReview => {
  const type = data.documentType as DocumentType
  const isLeaseOrSublease =
    type === DocumentType.LEASE || type === DocumentType.SUBLEASE

  return Reflect.has(data, 'lease') && isLeaseOrSublease
}
